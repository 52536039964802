import React, { lazy } from 'react';
import { Switch } from 'react-router';
import Authenticated from './guards/Authenticated';
import Guest from './guards/Guest';
import Simple from './guards/Simple';
import Router from 'admin/routes/router';
import suspenser from 'admin/hoc/Suspenser';
import { ToastContainer } from 'react-toastify';
import ReactDOM from 'react-dom';
import { isLocalhost } from 'admin/utils/Helpers';
import { Tooltip } from 'react-tooltip';
import { createPortal } from 'react-dom';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
/* Route containers start. */
const StoriesRouteContainer = lazy(() => import('./containers/stories'));
const VideosRouteContainer = lazy(() => import('./containers/videos'));
const PhotosetsRouteContainer = lazy(() => import('./containers/photosets'));
const MembersRouteContainer = lazy(() => import('./containers/members'));
const MemberTagsRouteContainer = lazy(() => import('./containers/member-tags'));
const TipsRouteContainer = lazy(() => import('./containers/tips'));
const StoreRouteContainer = lazy(() => import('./containers/store'));
const MembershipRoutesContainer = lazy(() => import('./containers/memberships'));
const MyAccountRouteContainer = lazy(() => import('./containers/my-account'));
const TransactionsRoutesContanier = lazy(() => import('./containers/transactions'));
const PayoutRoutes = lazy(() => import('./containers/payout'));
const MassMessageRoutesContainer = lazy(() => import('./containers/mass-message'));
const ChatRouteContainer = lazy(() => import('./containers/chat'));
const PromotionsRoutes = lazy(() => import('./containers/promotions'));
const LivStreameRoutes = lazy(() => import('./containers/live-stream'));
const AffiliatesAndReferralsRoutesContainer = lazy(() => import('./containers/affiliates-and-referals'));

/** MySite routes became separate Settings and Designer route containers */

// const MySiteRouteContainer = lazy(() => import('./containers/my-site'));
const DesignerRoutesContainer = lazy(() => import('./containers/designer'));
const SettingsRoutesContainer = lazy(() => import('./containers/settings'));

/* Route containers end. */

/* Page containers start. */
const UploadingContainer = lazy(() => import('admin/containers/pages/uploading'));
const SignInContainer = lazy(() => import("admin/containers/pages/auth/login"));
const SignupContainer = lazy(() => import("admin/containers/pages/auth/sign-up"));
const ConfirmSignupContainer = lazy(() => import("admin/containers/pages/auth/confirm-signup"));
const ForgotPasswordContainer = lazy(() => import("admin/containers/pages/auth/forgot-password"));
const ResetPasswordContainer = lazy(() => import("admin/containers/pages/auth/reset-password"));
const HomepageContainer = lazy(() => import("admin/containers/pages/homepage"));
const CastMemberContainer = lazy(() => import('admin/containers/pages/cast-members/list'));
const CastMemberEdit = lazy(() => import('admin/containers/pages/cast-members/edit'));
const CastMemberCreate = lazy(() => import('admin/containers/pages/cast-members/create'));
const ContentContainer = lazy(() => import('admin/containers/pages/content'));
const ManageTagsContainer = lazy(() => import('admin/containers/pages/tags'));
const ManageCollectionsContainer = lazy(() => import('admin/containers/pages/video-collections'));
const OneLinkReferralsContainer = lazy(() => import('admin/containers/pages/referrals/one-link'));

const NeedHelpContainer = lazy(() => import('admin/containers/pages/need-help'));
const FinancialsContainer = lazy(() => import('admin/containers/pages/financials'));
// const SettingsContainer = lazy(() => import('admin/containers/pages/settings'));
// const SettingsBlockingContainer = lazy(() => import('admin/containers/pages/settings/blocking'));
// const SettingsBlockingIpWhiteListContainer = lazy(() => import('admin/containers/pages/settings/blocking/ip-white-list'));
// const SettingsBlockingIpBlackListContainer = lazy(() => import('admin/containers/pages/settings/blocking/ip-black-list'));
// const SettingsBlockingMembersContainer = lazy(() => import('admin/containers/pages/settings/blocking/members/list'));
// const SettingsBlockingMembersShowContainer = lazy(() => import('admin/containers/pages/settings/blocking/members/show'));
// const SettingsBlockingRegionsContainer = lazy(() => import('admin/containers/pages/settings/blocking/regions/list'));
// const SettingsBlockingRegionsShowContainer = lazy(() => import('admin/containers/pages/settings/blocking/regions/show'));
// const SettingsDetailsContainer = lazy(() => import('admin/containers/pages/settings/details'));
// const SettingsOutgoingEmailsContainer = lazy(() => import('admin/containers/pages/settings/outgoing-emails'));
const MyDomainNameContainer = lazy(() => import('admin/containers/pages/domain-name'));
const VaultPhotosContainer = lazy(() => import('admin/containers/pages/vault'));
const VaultVideoShowContainer = lazy(() => import('admin/containers/pages/vault/show-video'));
const TwitterCallbackContainer = lazy(() => import('admin/containers/pages/twitter/callback'));
const SignInCannyContainer = lazy(() => import('admin/containers/pages/auth/sign-in-canny'));
const StatisticsRevenueContainer = lazy(() => import('admin/containers/pages/statistics/revenue'));
const StatisticsEngagementContainer = lazy(() => import('admin/containers/pages/statistics/engagement'));
const StatisticsAcquisitionContainer = lazy(() => import('admin/containers/pages/statistics/acquisition'))
const GlobalActivityContainer = lazy(() => import('admin/containers/pages/global-activity'));
const SignInWithContainer = lazy(() => import('admin/containers/pages/auth/sign-in-with'))
/* Page containers end. */


export default () => {
   return (
      <>
         <Switch>
            {/* Exact routes start */}
            <Redirect exact from='/' to={ window?.location?.search ?  `${ Router.route('HOMEPAGE').getMask() }${ window.location.search }` : Router.route('HOMEPAGE').getMask() } />
            <Authenticated exact path={ Router.route('HOMEPAGE').getMask() } component={ suspenser(HomepageContainer) } />
            <Authenticated exact path={ Router.route('CAST_MEMBERS').getMask() } component={ suspenser(CastMemberContainer) } />
            <Authenticated exact path={ Router.route('CAST_MEMBERS_CREATE').getMask() } component={ suspenser(CastMemberCreate) } />
            <Authenticated exact path={ Router.route('CAST_MEMBERS_EDIT').getMask() } component={ suspenser(CastMemberEdit) } />
            <Authenticated exact path={ Router.route('CONTENT').getMask() } component={ suspenser(ContentContainer) } />
            <Authenticated exact path={ Router.route('NEED_HELP').getMask() } component={ suspenser(NeedHelpContainer) } />
            <Authenticated exact path={ Router.route('FINANCIALS').getMask() } component={ suspenser(FinancialsContainer) } />
            {/* <Authenticated exact path={ Router.route('SETTINGS').getMask() } component={ suspenser(SettingsContainer) } />
            <Authenticated exact path={ Router.route('SETTINGS_BLOCKING').getMask() } component={ suspenser(SettingsBlockingContainer) } />
            <Authenticated exact path={ Router.route('SETTINGS_BLOCKING_IP_WHITE_LIST').getMask() } component={ suspenser(SettingsBlockingIpWhiteListContainer) } />
            <Authenticated exact path={ Router.route('SETTINGS_BLOCKING_IP_BLACK_LIST').getMask() } component={ suspenser(SettingsBlockingIpBlackListContainer) } />
            <Authenticated exact path={ Router.route('SETTINGS_BLOCKING_MEMBERS').getMask() } component={ suspenser(SettingsBlockingMembersContainer) } />
            <Authenticated exact path={ Router.route('SETTINGS_BLOCKING_MEMBERS_SHOW').getMask() } component={ suspenser(SettingsBlockingMembersShowContainer) } />
            <Authenticated exact path={ Router.route('SETTINGS_BLOCKING_REGIONS').getMask() } component={ suspenser(SettingsBlockingRegionsContainer) } />
            <Authenticated exact path={ Router.route('SETTINGS_BLOCKING_REGIONS_SHOW').getMask() } component={ suspenser(SettingsBlockingRegionsShowContainer) } />
            <Authenticated exact path={ Router.route('SETTINGS_DETAILS').getMask() } component={ suspenser(SettingsDetailsContainer) } />
            <Authenticated exact path={ Router.route('SETTINGS_OUTGOING_EMAILS').getMask() } component={ suspenser(SettingsOutgoingEmailsContainer) } /> */}
            <Authenticated path={ Router.route('MY_DOMAIN_NAME').getMask() } component={ suspenser(MyDomainNameContainer) } />
            <Authenticated path={ Router.route('TAGS').getMask() } component={ suspenser(ManageTagsContainer) } />
            <Authenticated path={ Router.route('UPLOADS').getMask() } component={ suspenser(UploadingContainer) } />
            <Authenticated path={ Router.route('COLLECTIONS').getMask() } component={ suspenser(ManageCollectionsContainer) } />
            <Authenticated path={ Router.route('REFERRALS').getMask() } component={ suspenser(OneLinkReferralsContainer) } />
            <Authenticated path={ Router.route('AFFILIATE_PROGRAM').getMask() } component={ suspenser(AffiliatesAndReferralsRoutesContainer) } />

            {/* Exact routes end */}

            {/* Not exact routes start */}
            <Authenticated path={ Router.route('PHOTOSETS').getMask() } component={ suspenser(PhotosetsRouteContainer) } />
            <Authenticated path={ Router.route('STORIES').getMask() } component={ suspenser(StoriesRouteContainer) } />
            <Authenticated path={ Router.route('VIDEOS').getMask() } component={ suspenser(VideosRouteContainer) } />
            <Authenticated path={ Router.route('MEMBERS').getMask() } component={ suspenser(MembersRouteContainer) } />
            <Authenticated path={ Router.route('MEMBER_TAGS').getMask() } component={ suspenser(MemberTagsRouteContainer) } />
            <Authenticated path={ Router.route('TIPS').getMask() } component={ suspenser(TipsRouteContainer) } />
            <Authenticated path={ Router.route('STORE').getMask() } component={ suspenser(StoreRouteContainer) } />
            <Authenticated path={ Router.route('MEMBERSHIPS').getMask() } component={ suspenser(MembershipRoutesContainer) } />
            <Authenticated path={ Router.route('MY_ACCOUNT').getMask() } component={ suspenser(MyAccountRouteContainer) } />
            <Authenticated path={ Router.route('TRANSACTIONS').getMask() } component={ suspenser(TransactionsRoutesContanier) } />
            <Authenticated path={ Router.route('PAYOUT').getMask() } component={ suspenser(PayoutRoutes) } />
            <Authenticated path={ Router.route('MASS_MESSAGE').getMask() } component={ suspenser(MassMessageRoutesContainer) } />
            <Authenticated path={ Router.route('CHAT').getMask() } component={ suspenser(ChatRouteContainer) } />
            <Authenticated path={ Router.route('PROMOTIONS').getMask() } component={ suspenser(PromotionsRoutes) } />

            <Authenticated path={ Router.route('LIVE_STREAM').getMask() } component={ suspenser(LivStreameRoutes) } />
            <Authenticated path={ Router.route('VAULT').getMask() } component={ suspenser(VaultPhotosContainer) } />
            <Authenticated path={ Router.route('GET_VAULT_VIDEO').getMask() } component={ suspenser(VaultVideoShowContainer) } />

            <Authenticated path={ Router.route('TWITTER_CALLBACK').getMask() } component={ suspenser(TwitterCallbackContainer) } />

            <Authenticated path={ Router.route('SIGN_IN_CANNY').getMask() } component={ suspenser(SignInCannyContainer) } />

            <Authenticated path={ Router.route('STATISTICS_REVENUE').getMask() } component={ suspenser(StatisticsRevenueContainer) } />
            <Authenticated path={ Router.route('STATISTICS_ENGAGEMENT').getMask() } component={ suspenser(StatisticsEngagementContainer) } />
            <Authenticated path={ Router.route('STATISTICS_ACQUISITION').getMask() } component={ suspenser(StatisticsAcquisitionContainer) } />
            <Authenticated path={ Router.route('GLOBAL_ACTIVITY').getMask() } component={ suspenser(GlobalActivityContainer) } />

            {/* MySite routes became separate Settings and Designer route containers */ }

            {/* <Authenticated path={ Router.route('MY_SITE').getMask() } component={ suspenser(MySiteRouteContainer) } /> */}
            <Authenticated path={ Router.route('MY_SITE_DESIGN').getMask() } component={ suspenser(DesignerRoutesContainer) } />
            <Authenticated path={ Router.route('MY_SITE_SETTINGS').getMask() } component={ suspenser(SettingsRoutesContainer) } />

            <Authenticated path={ Router.route('SIGN_IN_WITH').getMask() } component={ suspenser(SignInWithContainer) } />
            {/* Not exact routes end */}
            {
               !!isLocalhost() && (
                  <>
                     <Guest exact path={ Router.route('CONFIRM_SIGN_UP').getMask() } component={ suspenser(ConfirmSignupContainer) } />
                     <Guest exact path={ Router.route('SIGN_IN').getMask() } component={ suspenser(SignInContainer) } />
                     <Guest exact path={ Router.route('SIGN_UP').getMask() } component={ suspenser(SignupContainer) } />
                     <Guest exact path={ Router.route('PASSWORD_RESET').getMask() } component={ suspenser(ForgotPasswordContainer) } />
                     <Guest exact path={ Router.route('PASSWORD_RESET_TOKEN').getMask() } component={ suspenser(ResetPasswordContainer) } />
                  </>
               )
            }
            {
               !isLocalhost() && (
                  <>
                     <Simple exact path={ Router.route('CONFIRM_SIGN_UP').getMask() } />
                     <Simple exact path={ Router.route('SIGN_IN').getMask() } />
                     <Simple exact path={ Router.route('SIGN_UP').getMask() } />
                     <Simple exact path={ Router.route('PASSWORD_RESET').getMask() } />
                     <Simple exact path={ Router.route('PASSWORD_RESET_TOKEN').getMask() } />
                  </>
               )
            }
         </Switch>
         {
            ReactDOM.createPortal(
               <ToastContainer
                  className='ams-toast-container'
                  autoClose={ 3000 }
                  hideProgressBar
               />,
               document.getElementById('toasts')
            )
         }
         {
            createPortal(
               <Tooltip
                  className='ams-black-tooltips'
                  id='ams-bottom-tooltip'
                  place='bottom'
                  delayShow={ 300 }
               />,
               document.getElementById('tooltips')
            )
         }
         {
            createPortal(
               <Tooltip
                  className='ams-black-tooltips'
                  id='ams-right-tooltip'
                  place='right'
                  delayShow={ 300 }
               />,
               document.getElementById('tooltips')
            )
         }
         {
            createPortal(
               <Tooltip
                  className='ams-black-tooltips'
                  id='ams-top-tooltip'
                  place='top'
                  // isOpen={ true }
                  delayShow={ 300 }
               />,
               document.getElementById('tooltips')
            )
         }
         {
            createPortal(
               <Tooltip
                  className='ams-black-tooltips'
                  id='ams-left-tooltip'
                  place='left'
                  delayShow={ 300 }
               />,
               document.getElementById('tooltips')
            )
         }
         {
            createPortal(
               <Tooltip
                  className='ams-black-tooltips'
                  id='ams-top-tooltip-clickable'
                  place='top'
                  delayShow={ 300 }
                  clickable

               />,
               document.getElementById('tooltips')
            )
         }
         {
            createPortal(
               <Tooltip
                  className='ams-black-tooltips'
                  id='other-tooltip'
                  place='top'
                  delayShow={ 300 }

               />,
               document.getElementById('tooltips')
            )
         }
         {
            createPortal(
               <Tooltip
                  className='ams-black-tooltips'
                  id='ams-top-tooltip-start'
                  place='top-start'
                  delayShow={ 300 }
                  style={ {
                     opacity: '0.5',
                  } }
               />,
               document.getElementById('tooltips')
            )
         }
         {
            createPortal(
               <Tooltip
                  className='ams-black-tooltips'
                  id='ams-top-tooltip-end'
                  place='top-end'
                  delayShow={ 300 }
               />,
               document.getElementById('tooltips')
            )
         }
         {
            createPortal(
               <Tooltip
                  className='ams-black-tooltips'
                  id='ams-bottom-tooltip-start'
                  place='bottom-start'
                  delayShow={ 300 }
               />,
               document.getElementById('tooltips')
            )
         }
         {
            createPortal(
               <Tooltip
                  className='ams-black-tooltips'
                  id='ams-bottom-tooltip-end'
                  place='bottom-end'
                  delayShow={ 300 }
               />,
               document.getElementById('tooltips')
            )
         }
         {
            createPortal(
               <Tooltip
                  className='ams-black-tooltips'
                  id='ams-top-tooltip-clickable-start'
                  place='top-start'
                  delayShow={ 300 }
                  clickable
               />,
               document.getElementById('tooltips')
            )
         }
         {
            createPortal(
               <Tooltip
                  className='ams-black-tooltips'
                  id='ams-top-tooltip-clickable-end'
                  place='top-end'
                  delayShow={ 300 }
                  clickable
               />,
               document.getElementById('tooltips')
            )
         }
      </>
   )
}
