const initialState = {
   isSiteInited: false,
   isSiteDetailsRefreshing: false,
   authUser: null,
   screenWidth: typeof window === 'object' ? window.innerWidth : null,
   menu: {
      isMenuOpened: false,
      animationClass: 'default',
   },
   siteInfo: {},
   siteSteps: {},
   viewAsModal: {
      isOpen: false,
      url: null,
   },

   isOnlineStream: false,
   liveStreamCurrentSessionState: {
      isShowBanner: false,
      data: null,
   },
   isOpenOnlineMembersModal: false,
   isUpdateSiteLongInfo: false,
   siteLongNameUpdateError: null,
   massMessageLimit: {},
   isUpdateOfflineMode: false,
   accountType: null,
};

export default initialState;
